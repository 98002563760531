<template>
  <div>
    <song-list :songs="songs" v-on:loadSongs="loadSongs" :title="title" prefix="thanh-vien-hat"/>
  </div>
</template>


<script>
import ApiService from "../../core/services/api.service";
import SongList from "../song/SongList";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "KaraokeList",
  components: {
    SongList
  },
  data() {
    return {
      title: "Thành viên hát",
      songs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadSongs(options) {
      let query = `query($page: Int, $orderBy: [OrderByClause!]) {
        karaokes(first: 20, page: $page, orderBy: $orderBy) {
          data {
            id
            slug
            title
            views
            downloads
            thumbnail {
              url
            }
            sheet {
              year
              composers(first: 20) {
                data {
                  id
                  slug
                  title
                }
              }
            }
            users(first: 10) {
              data {
                username
                id
                avatar {
                  url
                }
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: options.page, orderBy: options.orderBy})
          .then(({data}) => {
            if (data.data && data.data.karaokes) {
              this.songs = data.data.karaokes;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    changePageTitle(this.title);
  }
}
</script>